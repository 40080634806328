<template >
  <b-overlay :show="isLoadingDetail" variant="light" :opacity="0.75" blur="2px">
    <div>
      <b-row>
        <b-col cols="12">
          <div class="mb-3">
            <div class="h3 d-flex align-items-center justify-content-between border-bottom">
              <span
                class="border-bottom-primary"
                style="border-bottom-width: 4px; padding-bottom: 4px;"
              >{{ $t('lbl_orders_detail') }}</span>
              <span>#{{ record.order_code }}</span>
            </div>
            <div class="pt-1">
              <ProductItem
                v-for="(product, index) in record.order_items"
                :class="index < record.order_items.length-1 ? 'mb-1 pb-1 border-bottom' : ''"
                :key="product.data.id"
                :product="product"
                :ordersId="record.id"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="8" xxl="9" class="border-right" style="margin-right: -1px;">
          <div>
            <div class="h3 d-flex align-items-center justify-content-between border-bottom">
              <span
                class="border-bottom-primary"
                style="border-bottom-width: 4px; padding-bottom: 4px;"
              >{{ $t('lbl_patient_information') }}</span>
            </div>
            <div class="pt-1">
              <div
                class="border-left-primary font-weight-bolder h5 mb-1"
                style="padding: 4px 0 4px 4px; border-left-width: 4px;"
              >{{ $t('lbl_orderer') }}</div>
              <ReceiverInfo :data="record.order_contact.find(x=> x.type === 1)" />
            </div>
            <!-- <div>
            <b-form-checkbox
              v-model="record.isPersonReceiver"
              :disabled="!isEdit"
              class="mb-2 mr-sm-2 mb-sm-0"
            >Use this information as the receiver</b-form-checkbox>
            </div>-->
            <div class="pt-1" v-if="!record.isPersonReceiver">
              <div
                class="border-left-primary font-weight-bolder h5 mb-1"
                style="padding: 4px 0 4px 4px; border-left-width: 4px;"
              >{{ $t('lbl_receiver') }}</div>
              <ReceiverInfo :data="record.order_contact.find(x=> x.type === 2)" />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="4" xxl="3" class="border-left">
          <!-- <div class="mb-3">
            <div class="h3 d-flex align-items-center justify-content-between border-bottom">
              <span
                class="border-bottom-primary"
                style="border-bottom-width: 4px; padding-bottom: 4px;"
              >Orders Status</span>
            </div>
            <OrdersStatus :data="record" />
          </div>-->
          <div>
            <div class="h3 d-flex align-items-center justify-content-between border-bottom">
              <span
                class="border-bottom-primary"
                style="border-bottom-width: 4px; padding-bottom: 4px;"
              >{{ $t('lbl_payment_methods') }}</span>
            </div>
            <PaymentMethods :data="record" />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BFormCheckboxGroup, BFormCheckbox } from "bootstrap-vue";
import appUtils from "@/utils/appUtils";
import { ORDERS_STATUS } from "@/utils/constant";
import ProductItem from "./Components/ProductItem.vue";
import ReceiverInfo from "./Components/ReceiverInfo.vue";
import OrdersStatus from "./Components/OrdersStatus.vue";
import PaymentMethods from "./Components/PaymentMethods.vue";
import Definition from "./Components/Definition.vue";

export default {
  name: "OrdersDetail",
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    ProductItem,
    ReceiverInfo,
    OrdersStatus,
    PaymentMethods,
    Definition
  },
  props: {
    recordData: {
      type: Object,
      required: true
    },
    isLoadingDetail: Boolean
  },
  data() {
    return {
      record: { ...this.recordData } || null,
      appUtils,
      ORDERS_STATUS,
      ordererForm: {},
      receiverForm: {},
      show: true,
      isEdit: false
    };
  },
  watch: {
    recordData: {
      handler(data) {
        this.record = { ...data };
        this.ordererForm = { ...this.record.orderer };
        this.receiverForm = { ...this.record.receiver };
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log(this.ordererForm);
      console.log(this.receiverForm);
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.ordererForm = {};
      this.receiverForm = {};
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style>
</style>
