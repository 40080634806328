import { computed, ref, watch } from "@vue/composition-api";

import { ORDERS_STATUS, ORDER_FORM_PROCESSOR_STATUS, ORDER_PROCESSOR_STATUS } from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n";
import store from "@/store";
import { useToast } from "vue-toastification/composition";

export default function useOrdersList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refOrdersTable = ref(null);

  // Table Handlers
  const tableColumns = computed(() => {
    const defaultCols = [
      {
        key: "show_details", label: i18n.t(""), sortable: false, thStyle: { width: "5%" },
      },

      // { key: "id", label: "#ID", sortable: false },
      { key: "order_code", label: i18n.t("lbl_code"), sortable: false, thStyle: { width: "20%" }, },
      { key: "customer", label: i18n.t("lbl_customer"), sortable: false, thStyle: { width: "20%" }, },
      // { key: "address", label: i18n.t("lbl_address"), sortable: false },
      // { key: "phone", label: i18n.t("lbl_phone"), sortable: false },
      { key: "status", label: i18n.t("lbl_order_status"), sortable: false, thStyle: { width: "10%" }, },
      { key: "avatar", label: i18n.t("Follower"), sortable: false, thStyle: { width: "10%" }, },
      { key: "order_date", label: i18n.t("lbl_order_date"), sortable: false, thStyle: { width: "10%" }, },
      { key: "return_date", label: i18n.t("lbl_result_date"), sortable: false, thStyle: { width: "10%", padding: "0.72rem 0.5rem" }, },
      { key: "action", label: "", thClass: "text-right" },
    ];
    return defaultCols
  });
  const tableItemColumns = computed(() => {
    const defaultCols = [
      {
        key: "show_details", label: i18n.t(""), sortable: false, thStyle: { width: "5%" },
      },

      // { key: "id", label: "#ID", sortable: false },
      { key: "order_code", label: i18n.t("lbl_code"), sortable: false, thStyle: { width: "20%" }, },
      { key: "customer", label: i18n.t("lbl_customer"), sortable: false, thStyle: { width: "20%" }, },
      // { key: "address", label: i18n.t("lbl_address"), sortable: false },
      // { key: "phone", label: i18n.t("lbl_phone"), sortable: false },
      { key: "status", label: i18n.t("lbl_order_status"), sortable: false, thStyle: { width: "10%" }, },
      { key: "avatar", label: i18n.t("Follower"), sortable: false, thStyle: { width: "10%" }, },
      { key: "order_date", label: i18n.t("lbl_order_date"), sortable: false, thStyle: { width: "10%" }, },
      { key: "return_date", label: i18n.t("lbl_result_date"), sortable: false, thStyle: { width: "10%", padding: "0.72rem 0.5rem" }, },
      { key: "action", label: "", thClass: "text-right" },
    ];
    return followMode.value ? [{ key: "selecting", label: "", sortable: false }, ...defaultCols] : defaultCols
  });

  const statusFilterOptions = computed(() => {
    return [
      {
        value: null,
        text: i18n.t("lbl_all_status"),
      },
      {
        value: ORDER_PROCESSOR_STATUS.NOT_COMPLETE,
        text: i18n.t("obj_order_processor_status.not_complete"),
      },
      {
        value: ORDER_PROCESSOR_STATUS.COMPLETE,
        text: i18n.t("obj_order_processor_status.completed"),
      },
      {
        value: ORDER_PROCESSOR_STATUS.CANCEL,
        text: i18n.t("obj_order_processor_status.canceled"),
      },
    ];
  });

  const perPage = ref(10);
  const totals = ref(0);
  const currentPage = ref(1);
  const searchQuery = ref("");
  const sortBy = ref("order_id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const activeFilter = ref(null);
  const allOpenRows = ref([]);
  const recordDetail = ref(null);
  const showDetailOrder = ref(false);
  const statusFilter = ref(null);
  const fromDate = ref(null);
  const toDate = ref(null);
  const followMode = ref("");
  const ordersData = ref([])
  const isLoading = ref(false);


  if (store.state.orders.filterParams.supplier_id) {
    (searchQuery.value = store.state.orders.filterParams.keyword),
      (perPage.value = store.state.orders.filterParams.page_size),
      (currentPage.value = store.state.orders.filterParams.page_num),
      (statusFilter.value = store.state.orders.filterParams.status),
      (fromDate.value = store.state.orders.filterParams.from_date),
      (toDate.value = store.state.orders.filterParams.to_date);
    (followMode.value = store.state.orders.filterParams.followMode);
  }

  const dataMeta = computed(() => {
    const localItemsCount = refOrdersTable.value
      ? refOrdersTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    };
  });

  const refetchData = () => {
    refOrdersTable.value.refresh();
  };


  const fetchOrdersList = async (ctx, callback) => {
    const userData = appUtils.getLocalUser();
    isLoading.value = true
    return await store
      .dispatch("ordersProcessor/fetchOrdersProcessorListV2", {
        keyword: searchQuery.value,
        page_size: perPage.value,
        page_num: currentPage.value,
        processor_id: userData?.processorsInfo?.id,
        sort_by: "assign_date",
        order: "desc",
        status: statusFilter.value,
        from_date: fromDate.value ? fromDate.value.getTime() : fromDate.value,
        to_date: toDate.value ? toDate.value.getTime() : toDate.value,
        mode: followMode.value
      })
      .then((response) => {
        const orders = response.result?.data?.map((item) => {
          const orderProcessorItem = item?.processor_orders?.[0]
          let status = ORDER_PROCESSOR_STATUS.NOT_COMPLETE



          if (orderProcessorItem) {
            const hasComplete = item?.processor_orders.every(processorItem => processorItem?.status === ORDER_FORM_PROCESSOR_STATUS.COMPLETE)
            const hasCanceled = item?.processor_orders.every(processorItem => processorItem?.status === ORDER_FORM_PROCESSOR_STATUS.CANCEL)

            if (hasComplete) {
              status = ORDER_PROCESSOR_STATUS.COMPLETE
            } else if (hasCanceled) {
              status = ORDER_PROCESSOR_STATUS.CANCEL
            }

            return ({
              ...item,
              _showDetails: true,
              ...orderProcessorItem,
              status
            })
          } else {
            return ({
              ...item,
              _showDetails: true,
              status
            })

          }
        });
        totals.value = response.result?.page?.total;
        ordersData.value = orders
        // const products = response.data.data;
        // totals.value = response.data.total;
        callback && callback(orders);

        return orders
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }).finally(() => {
        isLoading.value = false
      });
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case ORDERS_STATUS.RECEIVE_ORDERS:
        return {
          classes: "bg-primary text-white",
          icon: "LoaderIcon",
        };
      case ORDERS_STATUS.PROCESSING:
        return {
          classes: "bg-primary text-white",
          icon: "LoaderIcon",
        };
      case ORDERS_STATUS.SENDING:
        return {
          classes: "bg-primary text-white",
          icon: "LoaderIcon",
        };
      case ORDERS_STATUS.FINISHED:
        return {
          classes: "bg-success text-white",
          icon: "CheckIcon",
        };
      case ORDERS_STATUS.CANCELED:
        return {
          classes: "bg-danger text-white",
          icon: "XIcon",
        };
      case ORDERS_STATUS.WAITING_CONFIRM:
        return {
          classes: "bg-light",
          icon: "MoreHorizontalIcon",
        };
      case ORDERS_STATUS.ERROR_PAYMENT:
        return {
          classes: "bg-danger text-white",
          icon: "XIcon",
        };

      default:
        return {
          classes: "bg-white",
          icon: "",
        };
    }
  };

  const viewDetailOrders = (record) => {
    store
      .dispatch("ordersProcessor/fetchOrdersProcessorById", { id: record.id })
      .then((response) => {
        showDetailOrder.value = true;
        recordDetail.value = { ...response.result.data };
        // recordDetail.value.order_items.forEach((x) => {
        //   const currentStatus = x.data.status;
        //   const currentDeliveryStatus = x.deliverable_status.find(
        //     (i) => i.order_number === currentStatus
        //   );
        //   x.currentStatus = currentDeliveryStatus;
        // });
        store.commit("orders/updateViewDetail", recordDetail.value);
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateStatusOrder = (ordersId, params) => {
    store
      .dispatch("orders/updateStatusOrder", params)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_change_status_order_successfully"),
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_an_error_occurred_while_updating"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        viewDetailOrders({ id: ordersId });
      });
  };

  const uploadFileDefinition = async (
    file,
    order_item_attachment_id,
    ordersId
  ) => {
    const url = await uploadFile(file, order_item_attachment_id);
    if (!url) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_an_error_occurred_while_updating"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      return;
    }
    const params = {
      order_item_attachment_id,
      url,
    };

    store
      .dispatch("orders/updateAttachmentResult", {
        params,
        callback: () => viewDetailOrders({ id: ordersId }),
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_upload_file_successfully"),
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_an_error_occurred_while_uploading"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const uploadFile = async (file, order_item_attachment_id) => {
    const params = {
      file,
      FolderTarget: `attachmentOrderItem`,
      IdTarget: order_item_attachment_id,
    };
    const formData = new FormData();

    for (const key in params) {
      formData.append(key, params[key]);
    }

    const res = await Vue.prototype.$rf
      .getRequest("CommonRequest")
      .uploadFileTenant(formData);
    return res.data.fileUrl;
  };

  const timeout = ref(null);

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
      statusFilter,
      fromDate,
      toDate,
      followMode
    ],
    () => {
      showTable.value = true;
      clearTimeout(timeout);
      timeout.value = setTimeout(() => {
        fetchOrdersList();
        showTable.value = false;
      }, 600);
    }
  );

  return {
    timeout,
    showTable,
    fetchOrdersList,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrdersTable,
    tableItemColumns,
    refetchData,
    allOpenRows,
    ORDERS_STATUS,
    getOrderStatus,
    viewDetailOrders,
    recordDetail,
    showDetailOrder,
    updateStatusOrder,
    uploadFileDefinition,
    statusFilterOptions,
    statusFilter,
    fromDate,
    toDate,
    followMode,
    ordersData,
    isLoading
    // Extra
  };
}
