<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <LayoutDataTable
      :isLoading="showTable"
      :searchQuery="searchQuery"
      :perPage="perPage"
      :meta="dataMeta"
      :currentPage="currentPage"
      :totals="totals"
      :isShowTabs="true"
      :searchPlaceholder="$t('lbl_find_orders_by_order_code')"
      @setTab="handleTabClicked"
      @setPerPage="(value) => (perPage = value)"
      @setCurrentPage="(value) => (currentPage = value)"
      @setSearchQuery="(value) => (searchQuery = value)"
    >
      <template #default>
        <span class="ml-2">{{
          $t("lbl_filter_orders_by_expected_delivery_date_")
        }}</span>
        <b-row class="w-full pl-2 pr-3 d-flex" style="margin-top: 5px">
          <b-col>
            <b-row class="d-flex">
              <b-col style="min-width: 270px">
                <b-form-datepicker
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                  id="start-date"
                  :placeholder="$t('lbl_select_start_date')"
                  v-model="fromDate"
                  class="mb-2 mr-2"
                  value-as-date
                  today-button
                  reset-button
                ></b-form-datepicker>
              </b-col>
              <span
                class="mb-2 font-weight-bold"
                style="height: 39px; line-height: 39px"
                >-</span
              >
              <b-col style="min-width: 270px">
                <b-form-datepicker
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                  id="end-date"
                  :placeholder="$t('lbl_select_end_date')"
                  v-model="toDate"
                  class="mb-2"
                  value-as-date
                  today-button
                  reset-button
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-form-select
              class="ml-2 mr-1"
              placeholder
              v-model="statusFilter"
              :options="statusFilterOptions"
            />
          </b-col>
        </b-row>
        <b-row class="w-full pl-2 pr-3 d-flex">
          <b-col v-if="followMode === 'follow'">
            <b-button
              @click="handleClickBellIcon(null, true)"
              v-b-tooltip.top
              :title="
                selectedRows.length === 0
                  ? 'You have not selected any order.'
                  : ''
              "
              :disabled="selectedRows.length === 0"
              size="sm"
              variant="outline-primary"
              >Unfollow</b-button
            >
            <p class="d-inline-block mb-2 ml-2" style="font-size: 12px">
              Selected {{ selectedRows.length }} out of {{ perPage }} rows
            </p>
          </b-col>
          <b-col v-if="followMode === 'floating'">
            <b-button
              @click="handleClickBellIcon(null, false)"
              v-b-tooltip.top
              :title="
                selectedRows.length === 0
                  ? 'You have not selected any order.'
                  : ''
              "
              :disabled="selectedRows.length === 0"
              size="sm"
              variant="primary"
              class="mr-1"
              >Follow</b-button
            >
            <p class="d-inline-block mb-2 ml-2" style="font-size: 12px">
              Selected {{ selectedRows.length }} out of {{ perPage }} rows
            </p>
          </b-col>
        </b-row>

        <el-table
          ref="expandTable"
          :data="ordersData"
          style="width: 100%"
          empty-text="Không có dữ liệu"
          header-cell-class-name="cs-header-col"
          row-class-name="cursor-pointer"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.processor_orders"
                style="width: 100%"
                header-row-class-name="d-none"
                row-class-name="cursor-pointer info-row cs-bg-row"
                @row-click="handleViewProducts"
              >
                <el-table-column width="48px">
                  <template slot-scope="props">
                    <div
                      v-if="
                        followMode === 'follow' || followMode === 'floating'
                      "
                      class="flex justify-center"
                    >
                      <span class="d-inline-block" style="width: 20px">
                        <b-form-checkbox
                          v-model="props.row.isSelected"
                          @change="(e) => checkRow(e, props.row)"
                          style="width: 20px"
                        ></b-form-checkbox>
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('lbl_code')"
                  prop="order_code"
                  width="200px"
                >
                  <template slot-scope="props">
                    <div
                      v-if="props.row.order_read_flag === 1"
                      class="fade-loading"
                    ></div>
                    <span class="text-nowrap"> {{ props.row.order_code }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('lbl_customer')" prop="customer">
                  <template slot-scope="props">
                    <span class="text-nowrap">{{
                      getCustomer(props.row)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('lbl_status')" prop="status">
                  <template slot-scope="props">
                    <div :set="(status = getStatusOrder(props.row))">
                      <b-badge :variant="status.color">{{
                        status.title
                      }}</b-badge>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Follower')" prop="avatar">
                  <template slot-scope="props">
                    <div
                      class="d-flex flex-nowrap position-relative"
                      style="height: 40px"
                    >
                      <span
                        v-for="(follower, index) in props.row.followers"
                        :key="follower.id"
                        class="text-nowrap avatar position-absolute d-flex items-center justify-center"
                        :style="{
                          left: 20 * index + 'px',
                          zIndex: index.toExponential,
                          height: '38px',
                          top: 0,
                        }"
                      >
                        <b-img
                          v-if="follower.user.avatar"
                          :src="appUtils.getImageURL(follower.user.avatar)"
                          @error="
                            $set(follower, index, {
                              ...follower,
                              avatar: false,
                            })
                          "
                          fluid
                          alt=""
                          rounded="circle"
                          style="width: 40px; height: 40px"
                        ></b-img>
                        <span
                          v-else
                          class="b-avatar badge-minimal badge-light-primary rounded-circle"
                          style="
                            width: 40px;
                            height: 40px;
                            background-color: transparent;
                          "
                        >
                          <svg
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            focusable="false"
                            role="img"
                            aria-label="person fill"
                            aria-hidden="true"
                            alt="avatar"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi-person-fill b-icon bi"
                          >
                            <g>
                              <path
                                fill-rule="evenodd"
                                d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('lbl_order_date')"
                  prop="order_date"
                >
                  <template slot-scope="props">
                    <span class="text-nowrap">{{
                      formatDate(props.row.assign_date, "HH:mm DD/MM/YYYY")
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('lbl_result_date')"
                  prop="return_date"
                >
                  <template slot-scope>
                    <div class="text-nowrap">
                      {{
                        formatDate(props.row.return_date, "HH:mm DD/MM/YYYY")
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="props">
                    <div class="text-right text-nowrap">
                      <b-button
                        @click.stop="
                          handleClickBellIcon(
                            props.row.id,
                            getIsFollow(props.row.followers)
                          )
                        "
                        :set="(isFollow = getIsFollow(props.row.followers))"
                        v-b-tooltip.hover.top
                        :variant="isFollow ? 'outline-info' : 'outline-primary'"
                        size="sm"
                        :title="
                          isFollow
                            ? $t('lbl_unfollow_order_s_notification')
                            : $t('lbl_follow_order_s_notification')
                        "
                      >
                        <feather-icon
                          size="16"
                          :icon="isFollow ? 'BellOffIcon' : 'BellIcon'"
                        />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top
                        variant="outline-primary ml-1"
                        size="sm"
                        :title="$t('lbl_view_detail')"
                      >
                        <feather-icon size="16" icon="EyeIcon" />
                      </b-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('lbl_code')"
            prop="order_code"
            width="200px"
          >
            <template slot-scope="props">
              <span class="text-nowrap"> {{ props.row.order_code }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lbl_customer')" prop="customer">
            <template slot-scope="props">
              <span class="text-nowrap">{{ getCustomer(props.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lbl_status')" prop="status">
            <template slot-scope="props">
              <div :set="(status = getSupplierStatusOrder(props.row))">
                <b-badge :variant="status.color">{{ status.title }}</b-badge>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('Follower')" prop="avatar">
          </el-table-column>
          <el-table-column :label="$t('lbl_order_date')" prop="order_date">
            <template slot-scope="props">
              <span class="text-nowrap">{{
                formatDate(props.row.assign_date, "HH:mm DD/MM/YYYY")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('lbl_result_date')" prop="return_date">
            <template slot-scope>
              <div class="text-nowrap"></div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope>
              <div class="text-right text-nowrap">
                <!-- <b-button
                :set="(isFollow = getIsFollow(props.row.followers))"
                v-b-tooltip.hover.top
                :variant="isFollow ? 'outline-info' : 'outline-primary'"
                size="sm"
                :title="
                  isFollow
                    ? $t('lbl_unfollow_order_s_notification')
                    : $t('lbl_follow_order_s_notification')
                "
              >
                <feather-icon
                  size="16"
                  :icon="isFollow ? 'BellOffIcon' : 'BellIcon'"
                />
              </b-button> -->
                <b-button
                  v-b-tooltip.hover.top
                  variant="outline-primary ml-1"
                  size="sm"
                  :title="$t('lbl_view_detail')"
                >
                  <feather-icon size="16" icon="EyeIcon" />
                </b-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- <b-table
        ref="refOrdersTable"
        :items="fetchOrdersList"
        :fields="tableColumns"
        striped
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        :empty-text="$t('lbl_no_matching_records_found')"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="handleViewProducts"
        details-td-class="p-0 cs-tr-table"
        tbody-tr-class="cs-tr-table"
      >
        <template #cell(show_details)="row">
          <svg
            @click.stop="row.toggleDetails"
            width="20"
            height="20"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.7076 16.7076L12.7076 26.7076C12.6147 26.8005 12.5044 26.8742 12.383 26.9245C12.2616 26.9747 12.1315 27.0006 12.0001 27.0006C11.8687 27.0006 11.7386 26.9747 11.6172 26.9245C11.4958 26.8742 11.3855 26.8005 11.2926 26.7076C11.1997 26.6147 11.126 26.5044 11.0757 26.383C11.0254 26.2616 10.9995 26.1315 10.9995 26.0001C10.9995 25.8687 11.0254 25.7386 11.0757 25.6172C11.126 25.4958 11.1997 25.3855 11.2926 25.2926L20.5863 16.0001L11.2926 6.70757C11.1049 6.51993 10.9995 6.26543 10.9995 6.00007C10.9995 5.7347 11.1049 5.48021 11.2926 5.29257C11.4802 5.10493 11.7347 4.99951 12.0001 4.99951C12.2654 4.99951 12.5199 5.10493 12.7076 5.29257L22.7076 15.2926C22.8005 15.3854 22.8743 15.4957 22.9246 15.6171C22.975 15.7385 23.0009 15.8687 23.0009 16.0001C23.0009 16.1315 22.975 16.2616 22.9246 16.383C22.8743 16.5044 22.8005 16.6147 22.7076 16.7076Z"
              fill="#0C0C0D"
            />
          </svg>
        </template>
        <template #cell(order_code)="scope">
          <span class="text-nowrap">
            <div
              v-if="scope.item.order_read_flag === 1"
              class="fade-loading"
            ></div>
            {{ scope.item.order_code }}</span
          >
        </template>
        <template #cell(customer)="scope">
          <span style="visibility: hidden" class="text-nowrap">{{
            getCustomer(scope.item)
          }}</span>
        </template>

        <template #cell(status)="scope">
          <div
            style="visibility: hidden"
            :set="(status = getStatusOrder(scope.item))"
          >
            <b-badge :variant="status.color">{{ status.title }}</b-badge>
          </div>
        </template>
        <template #cell(avatar)="scope">
          <div
            style="width: 132px; visibility: hidden"
            class="d-flex flex-nowrap position-relative"
          >
            <span
              v-for="(follower, index) in scope.item.followers"
              :key="follower.id"
              class="text-nowrap avatar position-absolute d-flex items-center justify-center"
              :style="{
                left: 20 * index + 'px',
                zIndex: index.toExponential,
                height: '38px',
              }"
            >
              <b-img
                v-if="follower.user.avatar"
                :src="appUtils.getImageURL(follower.user.avatar)"
                @error="$set(follower, index, { ...follower, avatar: false })"
                fluid
                alt=""
                rounded="circle"
                style="width: 40px; height: 40px"
              ></b-img>
              <span
                v-else
                class="b-avatar badge-minimal badge-light-primary rounded-circle"
                style="width: 40px; height: 40px; background-color: transparent"
              >
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  focusable="false"
                  role="img"
                  aria-label="person fill"
                  aria-hidden="true"
                  alt="avatar"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi-person-fill b-icon bi"
                >
                  <g>
                    <path
                      fill-rule="evenodd"
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    ></path>
                  </g>
                </svg>
              </span>
            </span>
          </div>
        </template>
        <template #cell(order_date)="scope">
          <span style="width: 160px; visibility: hidden" class="text-nowrap">{{
            formatDate(scope.item.assign_date, "HH:mm DD/MM/YYYY")
          }}</span>
        </template>
        <template #cell(return_date)="scope">
          <div style="width: 160px; visibility: hidden" class="text-nowrap">
            {{ formatDate(scope.item.return_date, "HH:mm DD/MM/YYYY") }}
          </div>
        </template>
        <template #cell(action)="scope">
          <div style="visibility: hidden" class="text-right text-nowrap">
            <b-button
              :set="(isFollow = getIsFollow(scope.item.followers))"
              v-b-tooltip.hover.top
              :variant="isFollow ? 'outline-info' : 'outline-primary'"
              size="sm"
              :title="
                isFollow
                  ? $t('lbl_unfollow_order_s_notification')
                  : $t('lbl_follow_order_s_notification')
              "
            >
              <feather-icon
                size="16"
                :icon="isFollow ? 'BellOffIcon' : 'BellIcon'"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top
              variant="outline-primary ml-1"
              size="sm"
              :title="$t('lbl_view_detail')"
            >
              <feather-icon size="16" icon="EyeIcon" />
            </b-button>
          </div>
        </template>

        <template #row-details="row">
          <b-table
            class="position-relative"
            :items="row.item.processor_orders || []"
            responsive
            :fields="tableItemColumns"
            primary-key="id"
            show-empty
            hover
            thead-class="d-none"
            :empty-text="$t('lbl_no_matching_records_found')"
            @row-clicked="handleViewProducts"
          >
            <template #cell(show_details)>
              <div style="width: 20px"></div>
            </template>
            <template #cell(selecting)="scope">
              <span class="d-inline-block" style="width: 20px">
                <b-form-checkbox
                  v-model="scope.item.isSelected"
                  @change="(e) => checkRow(e, scope.item)"
                  style="width: 20px"
                ></b-form-checkbox>
              </span>
            </template>
            <template #cell(order_code)="scope">
              <span style="visibility: hidden" class="text-nowrap">
                <div
                  v-if="scope.item.order_read_flag === 1"
                  class="fade-loading"
                ></div>
                {{ scope.item.order_code }}</span
              >
            </template>
            <template #cell(customer)="scope">
              <span class="text-nowrap">{{ getCustomer(scope.item) }}</span>
            </template>

            <template #cell(status)="scope">
              <div :set="(status = getStatusOrder(scope.item))">
                <b-badge :variant="status.color">{{ status.title }}</b-badge>
              </div>
            </template>
            <template #cell(avatar)="scope">
              <div
                style="width: 132px"
                class="d-flex flex-nowrap position-relative"
              >
                <span
                  v-for="(follower, index) in scope.item.followers"
                  :key="follower.id"
                  class="text-nowrap avatar position-absolute d-flex items-center justify-center"
                  :style="{
                    left: 20 * index + 'px',
                    zIndex: index.toExponential,
                    height: '38px',
                  }"
                >
                  <b-img
                    v-if="follower.user.avatar"
                    :src="appUtils.getImageURL(follower.user.avatar)"
                    @error="
                      $set(follower, index, { ...follower, avatar: false })
                    "
                    fluid
                    alt=""
                    rounded="circle"
                    style="width: 40px; height: 40px"
                  ></b-img>
                  <span
                    v-else
                    class="b-avatar badge-minimal badge-light-primary rounded-circle"
                    style="
                      width: 40px;
                      height: 40px;
                      background-color: transparent;
                    "
                  >
                    <svg
                      viewBox="0 0 16 16"
                      width="1em"
                      height="1em"
                      focusable="false"
                      role="img"
                      aria-label="person fill"
                      aria-hidden="true"
                      alt="avatar"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      class="bi-person-fill b-icon bi"
                    >
                      <g>
                        <path
                          fill-rule="evenodd"
                          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </span>
              </div>
            </template>

            <template #cell(order_date)="scope">
              <span class="text-nowrap">{{
                formatDate(scope.item.assign_date, "HH:mm DD/MM/YYYY")
              }}</span>
            </template>
            <template #cell(return_date)="scope">
              <div style="width: 160px" class="text-nowrap">
                {{ formatDate(scope.item.return_date, "HH:mm DD/MM/YYYY") }}
              </div>
            </template>
            <template #cell(action)="scope">
              <div class="text-right text-nowrap" @click.stop>
                <b-button
                  :set="(isFollow = getIsFollow(scope.item.followers))"
                  v-b-tooltip.hover.top
                  :variant="isFollow ? 'outline-info' : 'outline-primary'"
                  size="sm"
                  :title="
                    isFollow
                      ? $t('lbl_unfollow_order_s_notification')
                      : $t('lbl_follow_order_s_notification')
                  "
                  @click="
                    handleClickBellIcon(
                      scope.item.id,
                      getIsFollow(scope.item.followers)
                    )
                  "
                >
                  <feather-icon
                    size="16"
                    :icon="isFollow ? 'BellOffIcon' : 'BellIcon'"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top
                  variant="outline-primary ml-1"
                  size="sm"
                  :title="$t('lbl_view_detail')"
                  @click="viewDetailOrders(scope.item)"
                >
                  <feather-icon size="16" icon="EyeIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </b-table> -->
      </template>
    </LayoutDataTable>
  </b-overlay>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";

import { BTable, BButton, BTooltip, BModal } from "bootstrap-vue";
import { mapGetters, mapState } from "vuex";
import useOrdersList from "./useOrdersList";
import { ref } from "@vue/composition-api";
import LayoutDataTable from "../../layouts/table/LayoutDataTable.vue";
import appUtils from "@/utils/appUtils";
import OrdersDetail from "./Detail/index.vue";
import {
  ORDER_FORM_PROCESSOR_STATUS,
  ORDER_PROCESSOR_STATUS,
} from "@/utils/constant";
import { Table, TableColumn } from "element-ui";

export default {
  name: "OrdersList",
  components: {
    LayoutDataTable,
    BTable,
    BButton,
    BTooltip,
    BModal,
    OrdersDetail,
    "el-table": Table,
    "el-table-column": TableColumn,
  },
  data() {
    return {
      appUtils,
      selectedRows: [],
      user: {},
    };
  },
  computed: {
    ...mapGetters("app", ["statusOptions"]),
    ...mapState({
      orders: (state) => state.orders,
    }),
    dataFilter() {
      return {
        currentPage: this.currentPage,
        perPage: this.perPage,
        searchQuery: this.searchQuery,
        outOfStockFilter: this.outOfStockFilter,
        activeFilter: this.activeFilter,
        statusFilter: this.statusFilter,
        fromDate: this.fromDate,
        toDate: this.toDate,
        followMode: this.followMode,
      };
    },
  },
  watch: {
    dataFilter: {
      deep: true,
      handler() {
        this.handleGetOrderList();
      },
    },
    //   (
    //   [

    //   ],
    //   () => {
    //     showTable.value = true;
    //     clearTimeout(timeout);
    //     timeout.value = setTimeout(() => {
    //       fetchOrdersList();
    //       showTable.value = false;
    //     }, 600);
    //   }
    // );
  },
  async created() {
    this.user = appUtils.getLocalUser();
    this.handleGetOrderList();
  },
  methods: {
    handleTabClicked(e) {
      switch (Number(e.target.ariaPosInSet)) {
        case 1:
          this.followMode = "";
          break;
        case 2:
          this.followMode = "follow";
          break;
        case 3:
          this.followMode = "floating";
          break;
        default:
          this.followMode = "";
          break;
      }
      this.selectedRows = [];
    },
    checkRow(eventValue, row) {
      if (eventValue) {
        this.selectedRows.push(row);
        this.refOrdersTable.localItems[index].isSelected = true;
      } else {
        this.selectedRows = this.selectedRows.filter(
          (item) => item.id !== row.id
        );
        this.refOrdersTable.localItems[index].isSelected = false;
      }
    },
    getIsFollow(followers) {
      return followers?.map((f) => f.user_id).includes(this.user.id) || false;
    },
    async handleClickBellIcon(orderId, isFollow) {
      const param = {
        object_id: orderId ? [orderId] : this.selectedRows.map((row) => row.id),
        type: 2,
      };
      if (isFollow) {
        await this.$rf.getRequest("CommonRequest").unfollowOrders(param);
      } else {
        await this.$rf.getRequest("CommonRequest").followOrders(param);
      }
      this.handleGetOrderList();
    },
    getCustomer(row) {
      if (row.product_order.ContactOrder) {
        const customerSender = row.product_order.ContactOrder.find(
          (x) => x.type === 1
        );
        return customerSender?.name;
      } else {
        return "";
      }
    },
    getCustomerAddress(row) {
      if (row.product_order.ContactOrder) {
        const customerSender = row.product_order.ContactOrder.find(
          (x) => x.type === 1
        );
        return customerSender?.address1 || customerSender?.address2;
      } else {
        return "";
      }
    },
    getCustomerPhone(row) {
      if (row.product_order.ContactOrder) {
        const customerSender = row.product_order.ContactOrder.find(
          (x) => x.type === 1
        );
        return customerSender?.phone;
      } else {
        return "";
      }
    },
    getStatusOrder(row) {
      console.log(row);
      switch (row?.status) {
        case ORDER_FORM_PROCESSOR_STATUS.PENDING:
          return {
            title: this.$t("lbl_order_processor_pending"),
            color: "primary",
          };
        case ORDER_FORM_PROCESSOR_STATUS.PROCESSING:
          return {
            title: this.$t("lbl_order_processor_process"),
            color: "info",
          };
        case ORDER_FORM_PROCESSOR_STATUS.COMPLETE:
          return {
            title: this.$t("lbl_order_processor_complete"),
            color: "success",
          };
        case ORDER_FORM_PROCESSOR_STATUS.CANCEL:
        case ORDER_FORM_PROCESSOR_STATUS.SUPPLIER_CANCEL:
          return {
            title: this.$t("lbl_order_processor_cancel"),
            color: "danger",
          };

        default:
          return {
            title: this.$t("lbl_order_processor_pending"),
            color: "primary",
          };
      }
    },
    getSupplierStatusOrder(row) {
      switch (row?.status) {
        case ORDER_PROCESSOR_STATUS.NOT_COMPLETE:
          return {
            title: this.$t("obj_order_processor_status.not_complete"),
            color: "primary",
          };

        case ORDER_PROCESSOR_STATUS.COMPLETE:
          return {
            title: this.$t("obj_order_processor_status.completed"),
            color: "success",
          };
        case ORDER_PROCESSOR_STATUS.CANCEL:
          return {
            title: this.$t("obj_order_processor_status.canceled"),
            color: "danger",
          };

        default:
          return {
            title: this.$t("obj_order_processor_status.not_complete"),
            color: "primary",
          };
      }
    },
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.handleGetOrderList();
        this.showTable = false;
      }, 2000);
    },
    formatDate(time, format = "DD/MM/YYYY") {
      if (!time) return;
      const date = moment.utc(time).format();
      return moment.utc(date).local().format(format);
    },
    handleViewProducts(item) {
      const userData = appUtils.getLocalUser();

      if (userData?.processors?.length) {
        this.viewDetailOrders(item);
      }
    },
    deleteOrders(record) {
      if (!record?.order_id) {
        return;
      }

      const nodeEl = this.$createElement;
      const messageVNode = nodeEl("div", {
        domProps: {
          innerHTML: `Please confirm that you want to delete orders <strong>#${record.order_id}</strong>.`,
        },
      });

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // Code here...
            console.log(value);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    viewDetailOrders(order) {
      console.log(order);
      this.$router
        .push({
          name: "detail-order-processor",
          params: { id: order.id },
        })
        .catch((_) => {});
    },
    async handleGetOrderList() {
      const response = await this.fetchOrdersList();

      if (response) {
        response.forEach((row, index) => {
          if (index === 0) {
            this.$refs.expandTable.toggleRowExpansion(row, true);
          }
        });
      }
    },
  },
  setup() {
    const dataTag = ref({});
    const {
      timeout,
      showTable,
      fetchOrdersList,
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdersTable,
      tableItemColumns,
      refetchData,
      allOpenRows,
      ORDERS_STATUS,
      getOrderStatus,
      recordDetail,
      showDetailOrder,
      statusFilter,
      statusFilterOptions,
      fromDate,
      toDate,
      followMode,
      ordersData,
      isLoading,
    } = useOrdersList();

    return {
      timeout,
      showTable,
      dataTag,
      fetchOrdersList,
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdersTable,
      tableItemColumns,
      refetchData,
      allOpenRows,
      ORDERS_STATUS,
      getOrderStatus,
      recordDetail,
      showDetailOrder,
      statusFilter,
      statusFilterOptions,
      fromDate,
      toDate,
      followMode,
      ordersData,
      isLoading,
    };
  },
};
</script>

<style lang="scss">
.avatar {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  width: 40px;
  top: -20px;
}
.b-table-details {
  background-color: #f6f6f9;
}
.fade-loading {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #2cccff;
  border-radius: 100rem;
  position: relative;
}

.fade-loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: inherit;
  animation: fade 1s forwards infinite linear;
}

@keyframes fade {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.cs-tr-table {
  height: 40px !important;
}

.cs-header-col * {
  word-break: break-word !important;
}

.cs-bg-row {
  background-color: #e2f7fe !important;
}
</style>
